import { Routes, RouterModule, ResolveFn } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import {
  LoginContainerComponent,
  PasswordresetContainerComponent,
  ForgotPasswordContainerComponent,
  CodeVerificationContainerComponent,
  ForceResetPasswordContainerComponent,
  LoginAuthGuard,
  QuantumLoginComponent,
  SignupRouteComponent,
  VerifyAccountRouteComponent,
  ExternalUserLoginRouteComponent,
  ExternalUserLoginAuthGuard,
} from '@arc/arc-shared-frontend';
import { UserProfileContainerComponent } from '@arc/arc-user-frontend';
import { SubscriberAgreementComponent } from '@arc/unified-payments-frontend/payment-footer';
import { CheckoutContainerComponent } from './checkout/checkout.container.component';
import {
  BuyerLoginConfig,
  BuyerLoginContainerComponent,
} from './checkout/buyerlogin/buyerlogin.container.component';
import { SupplierLoginContainerComponent } from './checkout/supplierlogin/supplierlogin.container.component';
import { Observable, of } from 'rxjs';
import {
  SessionTimeoutComponent,
  paymentLinksCheckoutResolver,
  platformCheckoutResolver,
  hostedPaymentsPageCheckoutResolver,
} from '@arc/unified-payments-frontend/shared-payment';

const paymentLinkLoginResolver: ResolveFn<
  Observable<BuyerLoginConfig>
> = () => {
  return of({
    applicationId: 'payments-app',
    featureId: 'arc-feature-paymentlinks',
    personaId: 'btpay-paymentlinks-payor',
  });
};

const hostedPaymentsPageLoginResolver: ResolveFn<
  Observable<BuyerLoginConfig>
> = () => {
  return of({
    applicationId: 'payments-app',
    featureId: 'arc-feature-hosted-payments-page',
    personaId: 'btpay-hosted-payments-page-payor',
  });
};

const platformCheckoutLoginResolver: ResolveFn<
  Observable<BuyerLoginConfig>
> = () => {
  return of({
    applicationId: 'arc-quantum',
    featureId: 'arc-feature-paymentscore',
    personaId: 'common-checkout-feature-persona',
  });
};

export const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'sepa',
    resolve: { platformCheckoutResolver },
    loadChildren: () =>
      import('@arc/unified-payments-frontend/sepa-redirect').then(
        (m) => m.SepaRedirectModule,
      ),
  },
  {
    path: ':supplierName/onetimepayment/login',
    resolve: { config: hostedPaymentsPageLoginResolver },
    component: BuyerLoginContainerComponent,
    children: [{ path: '', component: LoginContainerComponent }],
  },
  {
    path: ':supplierName/onetimepaymentnv/login',
    resolve: { config: hostedPaymentsPageLoginResolver },
    component: BuyerLoginContainerComponent,
    children: [{ path: '', component: LoginContainerComponent }],
  },
  {
    path: ':supplierName/onetimepayment',
    resolve: { hostedPaymentsPageCheckoutResolver },
    component: CheckoutContainerComponent,
    loadChildren: () =>
      import(
        '@arc/unified-payments-frontend/hosted-payments-page-domestic'
      ).then((m) => m.HostedPaymentsPageDomesticModule),
  },
  {
    path: ':supplierName/onetimepaymentnv',
    resolve: { hostedPaymentsPageCheckoutResolver },
    component: CheckoutContainerComponent,
    loadChildren: () =>
      import(
        '@arc/unified-payments-frontend/hosted-payments-page-domestic'
      ).then((m) => m.HostedPaymentsPageDomesticModule),
  },
  {
    path: 'paymentlinks/:tenantId/:sessionId/intl',
    component: CheckoutContainerComponent,
    resolve: { paymentLinksCheckoutResolver },
    canActivate: [ExternalUserLoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/make-payment-international').then(
        (m) => m.MakePaymentInternationalModule,
      ),
  },
  {
    path: 'paymentlinks/:tenantId/:sessionId',
    component: CheckoutContainerComponent,
    canActivate: [ExternalUserLoginAuthGuard],
    children: [
      {
        resolve: { paymentLinksCheckoutResolver },
        path: '',
        loadChildren: () =>
          import('@arc/unified-payments-frontend/make-payment').then(
            (m) => m.MakePaymentModule,
          ),
      },
    ],
  },
  {
    path: 'paymentlinks',
    loadChildren: () =>
      import('@arc/unified-payments-frontend/payment-links').then(
        (m) => m.PaymentLinksModule,
      ),
  },
  {
    path: 'login',
    component: SupplierLoginContainerComponent,
    children: [{ path: '', component: LoginContainerComponent }],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'buyerlogin',
    resolve: { config: platformCheckoutLoginResolver },
    component: BuyerLoginContainerComponent,
    children: [{ path: '', component: LoginContainerComponent }],
  },
  {
    path: 'paymentlinklogin',
    resolve: { config: paymentLinkLoginResolver },
    component: BuyerLoginContainerComponent,
    children: [{ path: '', component: LoginContainerComponent }],
  },
  {
    path: 'forgotpassword',
    component: SupplierLoginContainerComponent,
    children: [{ path: '', component: ForgotPasswordContainerComponent }],
  },
  {
    path: 'verificationcode',
    component: SupplierLoginContainerComponent,
    children: [{ path: '', component: CodeVerificationContainerComponent }],
  },

  {
    path: 'resetpassword',
    component: QuantumLoginComponent,
    children: [{ path: '', component: PasswordresetContainerComponent }],
  },
  {
    path: 'newpasswordrequired',
    component: SupplierLoginContainerComponent,
    children: [{ path: '', component: ForceResetPasswordContainerComponent }],
  },
  {
    path: 'signup',
    component: SupplierLoginContainerComponent,
    children: [{ path: '', component: SignupRouteComponent }],
    canActivate: [LoginAuthGuard],
  },
  {
    path: 'verifyphonenumber',
    component: SupplierLoginContainerComponent,
    children: [{ path: '', component: VerifyAccountRouteComponent }],
  },

  {
    path: 'checkout/:tenantId/:sessionId/intl',
    component: CheckoutContainerComponent,
    resolve: { platformCheckoutResolver },
    canActivate: [LoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/make-payment-international').then(
        (m) => m.MakePaymentInternationalModule,
      ),
  },
  {
    path: 'buyercheckout/:tenantId/:sessionId/intl',
    component: CheckoutContainerComponent,
    resolve: { platformCheckoutResolver },
    canActivate: [ExternalUserLoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/make-payment-international').then(
        (m) => m.MakePaymentInternationalModule,
      ),
  },
  {
    path: 'buyercheckout/:tenantId/:sessionId/payonaccount',
    component: CheckoutContainerComponent,
    resolve: { platformCheckoutResolver },
    canActivate: [ExternalUserLoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/pay-on-account-domestic').then(
        (m) => m.PayOnAccountDomesticModule,
      ),
  },
  {
    //Supplier side Pay on Account
    path: 'checkout/:tenantId/:sessionId/payonaccount',
    component: CheckoutContainerComponent,
    resolve: { platformCheckoutResolver },
    canActivate: [LoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/pay-on-account-domestic').then(
        (m) => m.PayOnAccountDomesticModule,
      ),
  },
  {
    path: 'checkout/:tenantId/:sessionId',
    component: CheckoutContainerComponent,
    resolve: { platformCheckoutResolver },
    canActivate: [LoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/make-payment').then(
        (m) => m.MakePaymentModule,
      ),
  },
  {
    path: 'buyercheckout/:tenantId/:sessionId',
    component: CheckoutContainerComponent,
    resolve: { platformCheckoutResolver },
    canActivate: [ExternalUserLoginAuthGuard],
    loadChildren: () =>
      import('@arc/unified-payments-frontend/make-payment').then(
        (m) => m.MakePaymentModule,
      ),
  },
  {
    path: 'sessiontimeout',
    component: SessionTimeoutComponent,
  },
  {
    path: 'profile',
    component: UserProfileContainerComponent,
    canActivate: [LoginAuthGuard],
  },
  {
    path: 'externallogin',
    component: ExternalUserLoginRouteComponent,
    children: [{ path: '', component: LoginContainerComponent }],
  },
  {
    path: 'subscriber-agreement',
    component: SubscriberAgreementComponent,
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AppRoutes: ModuleWithProviders<any> = RouterModule.forRoot(
  routes,
  {
    onSameUrlNavigation: 'reload',
  },
);
