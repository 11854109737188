import {
  Component,
  AfterViewInit,
  OnInit,
  ElementRef,
  ViewChild,
  OnDestroy,
  NgZone,
  HostListener,
  Renderer2,
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import { filter, tap, map, switchMap, shareReplay, take } from 'rxjs/operators';

import {
  Logout,
  AuthUserStore,
  AuthStore,
  BrowserStorageService,
  getActiveTenant$,
  PermissionTypes,
  ActiveTenantStore,
  AppConfigSettings,
  CoreService,
  UserNotificationAction,
  UserState,
} from '@arc/arc-shared-frontend';

import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import {
  Router,
  ActivatedRoute,
  Params,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  RouterEvent,
  Event as AppRouterEvent,
} from '@angular/router';

import { MenuItem, MessageService } from '@arc/aureus';
import { Actions, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { ScrollPanel } from 'primeng/scrollpanel';
import { BrandingService } from './store/services/branding.service';
import {
  GetBrandingCssTemplateAction,
  GetBrandingDataForTenantAction,
  BrandingActionsUnion,
} from './store/branding/actions/branding.actions';
import { TenantBrandingDataStore } from './store/branding/reducers/branding.reducer';
import { BrandingActions } from './store/branding/actions';

enum MenuOrientation {
  STATIC,
  OVERLAY,
  SLIM,
  HORIZONTAL,
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  primaryNav: MenuItem[];
  secondaryNav: MenuItem[];

  profileItems: MenuItem[];
  headerLogoUrl = './assets/layout/images/logo.svg';
  showTenantSwitcher = false;
  showTenantSwitcherButton$;
  notifications = [];
  stickyNotifications = [];
  isloggedin$: Observable<boolean>;
  hasMultipleTenants$ = this.store.pipe(
    select(AuthUserStore),
    map(
      (user) =>
        user &&
        user.user &&
        user.user.tenants &&
        Array.isArray(user.user.tenants) &&
        user.user.tenants.length > 1,
    ),
  );

  tenant$;
  layoutCompact = true;

  isBusy$;
  showSessionTimeOut$: Observable<boolean>;

  layoutMode: MenuOrientation = MenuOrientation.HORIZONTAL;

  _subSink = new SubSink();
  darkMenu = false;
  profileMode = 'inline';

  rotateMenuButton: boolean;

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  rightPanelActive: boolean;

  rightPanelClick: boolean;

  layoutContainer: HTMLDivElement;

  layoutMenuScroller: HTMLDivElement;

  menuClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: boolean;

  resetMenu: boolean;

  menuHoverActive: boolean;

  testTenant = false;

  testTenantMode$;

  loading = true;

  @ViewChild('layoutContainer', { static: true })
  layourContainerViewChild: ElementRef;

  @ViewChild(ScrollPanel)
  layoutMenuScrollerViewChild: ScrollPanel;

  rippleInitListener: EventListenerOrEventListenerObject;

  rippleMouseDownListener: EventListenerOrEventListenerObject;
  hasTenant$ = this.store.pipe(
    select(ActiveTenantStore),
    map((x) => x && x.tenantId),
    map((t) => !!t),
    shareReplay(1),
  );

  changeProfileItem = {
    command: (): void => this.gotoProfile(),
    icon: 'account_box',
    label: 'ARC_COMPOSITE_MENU_PROFILE',
    id: 'profile-item',
  };

  changeCompanyItem = {
    command: (): void => this.toggleSwitchTenant(),
    icon: 'swap_calls',
    label: 'ARC_COMPOSITE_MENU_CHANGE_COMPANY',
    id: 'change-company-item',
    visible$: this.store.pipe(
      select(AuthUserStore),
      map(
        (user) =>
          user &&
          user.user &&
          user.user.tenants &&
          Array.isArray(user.user.tenants) &&
          user.user.tenants.length > 1,
      ),
    ),
  };

  logOutItem = {
    command: (): void => this.logout(),
    icon: 'power_settings_new',
    label: 'ARC_COMPOSITE_MENU_LOGOUT',
    id: 'logout-item',
  };

  signupItem = {
    label: 'Sign Up',
    icon: 'assignment_ind',
    routerLink: ['/signup'],
  };

  // testModeItem = {
  //   command: () => this.toggleTestTenant(),
  //   icon: this.testTenantToggleIcon,
  //   label: this.testTenantToggleLabel,
  //   id: 'test-mode-item',
  //   visible$: this.store.pipe(
  //     select(ActiveTenantStore),
  //     filter(x => !!x),
  //     map(
  //       activeTenant =>
  //         (activeTenant && activeTenant.isTestTenant)
  //     )
  //   )
  // };

  get testTenantToggleIcon(): string {
    return this.testTenant ? 'toggle_off' : 'toggle_on';
  }

  get testTenantToggleLabel(): string {
    return `Switch to ${this.testTenant ? 'Production' : 'Test'} Mode`;
  }

  get showBackDrop(): boolean {
    return this.coreService.showBackDrop;
  }

  set showBackDrop(value: boolean) {
    this.coreService.showBackDrop = value;
  }

  private _isLive = true;
  private _isFirstLoad = true;
  private _switcherOkClicked = false;
  private _switcherButtonClicked = false;

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(): void {
    // this.hasUnsavedData()
    // if (false) {
    //   $event.returnValue = true;
    // }
    return null;
  }

  get showBlockUILoading(): boolean {
    return this.coreService.isLoading;
  }

  constructor(
    public renderer: Renderer2,
    private store: Store<UserState>,
    public zone: NgZone,
    public httpClient: HttpClient,
    public storageService: BrowserStorageService,
    public router: Router,
    private translationService: TranslateService,
    private permissionDefinition: PermissionTypes,
    private appConfigSetting: AppConfigSettings,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute,
    private actions$: Actions<BrandingActionsUnion>,
    private coreService: CoreService,
    private brandingService: BrandingService,
  ) {
    // const user$ = this.store.pipe(
    //   select(AuthUserStore),
    //   filter(x => x && x.user && !!x.user.userId),
    //   distinctUntilChanged((x, y) => x.user.userId === y.user.userId)
    // );

    this.profileItems = [
      // this.signupItem,
      this.changeProfileItem,
      this.changeCompanyItem,
      this.logOutItem,
    ];
    this.isloggedin$ = this.store.pipe(
      select(AuthStore),
      map((user) => user.user.loggedIn),
    );
    this.tenant$ = getActiveTenant$(this.store).pipe(
      switchMap((x) =>
        this.permissionDefinition
          .isPermitted$(
            'arc-permission-section-tenant-management-applicationandfeaturemanagement:read',
          )
          .pipe(map((t) => t && x.isPartner)),
      ),
      // map((x: UserTenant) => x.isPartner)
    );

    this.showSessionTimeOut$ = this.store.pipe(
      select(AuthUserStore),
      map((x) => x.sessionTimeOutPopUp),
    );

    this.primaryNav = [];
    this.secondaryNav = [
      // {
      //   icon: 'settings',
      //   id: 'settings-tab',
      //   label: 'ARC_COMPOSITE_MENU_SETTINGS',
      //   visible$: this.hasTenant$,
      //   items: [
      //   ]
      // }
      // {
      //   id: 'help-tab',
      //   icon: 'help',
      //   label: 'Help',
      //   visible: true,
      //   items: [{}]
      // }
    ];
    this._subSink.add(
      router.events
        .pipe(
          filter(
            (event): event is AppRouterEvent => event instanceof RouterEvent,
          ),
          tap((routerEvent: AppRouterEvent) => {
            this.checkRouterEvent(routerEvent);
          }),
        )
        .subscribe(),
    );
  }

  ngAfterViewInit(): void {
    this.layoutContainer = <HTMLDivElement>(
      this.layourContainerViewChild.nativeElement
    );
    if (this.layoutMenuScrollerViewChild) {
      setTimeout(() => {
        this.layoutMenuScrollerViewChild.moveBar();
      }, 100);
    }
  }

  ngOnInit(): void | boolean {
    try {
      if (!localStorage) {
        this.store.dispatch(
          new UserNotificationAction({
            type: 'warning',
            message: this.translationService.instant(
              'ARC_COMPOSITE_NO_LOCAL_STORAGE_ERROR',
            ) as string,
          }),
        );
      }
    } catch (e) {
      this.store.dispatch(
        new UserNotificationAction({
          type: 'warning',
          message: this.translationService.instant(
            'ARC_COMPOSITE_NO_LOCAL_STORAGE_ERROR',
          ) as string,
        }),
      );
    }

    this.zone.runOutsideAngular(() => {
      this.bindRipple();
    });

    this._subSink.add(
      this.actions$
        .pipe(
          ofType(BrandingActions.GetBrandingCssTemplateCompleteAction.type),
          map((action) => action.payload),
        )
        .subscribe((x) => {
          this.brandingService.updateBrandingColorsByCssStyle(x);
        }),
    );

    this._subSink.add(
      getActiveTenant$(this.store)
        .pipe(map((x) => x.isTestTenant))
        .subscribe((isTestTenantMode) => {
          this.testTenant = isTestTenantMode;
        }),
    );

    this._subSink.add(
      getActiveTenant$(this.store).subscribe((tenant) => {
        this.store.dispatch(
          GetBrandingCssTemplateAction({
            payload: { tenantId: tenant.tenantId, appName: 'btpay' },
          }),
        );
        this.store.dispatch(
          GetBrandingDataForTenantAction({
            payload: { tenantId: tenant.tenantId },
          }),
        );
      }),
    );

    this._subSink.add(
      this.store.pipe(select(AuthUserStore)).subscribe((x: UserState) => {
        if (x && x.user && x.user.defaultLanguage) {
          this.translationService.use(x.user.defaultLanguage);
        } else if (this.translationService.currentLang !== 'en') {
          this.translationService.use('en');
        }
      }),
    );

    this._subSink.add(
      this.store.pipe(select(TenantBrandingDataStore)).subscribe((x) => {
        if (x?.brandingDetails?.colors) {
          this.headerLogoUrl = null;

          if (x?.brandingDetails?.images?.headerLogoUrl) {
            this.headerLogoUrl = x?.brandingDetails?.images?.headerLogoUrl;
          }
        }
      }),
    );

    this._subSink.add(
      this.activatedRoute.queryParams.subscribe((x) => {
        if (x) {
          this.showTenantSwitcher = x.displayChangeCompany === 'true';
          if (x.tenantId) {
            this.store.dispatch(
              GetBrandingCssTemplateAction({
                payload: { tenantId: x.tenantId as string, appName: 'btpay' },
              }),
            );
          }
        }
      }),
    );

    return null;
  }

  bindRipple(): void {
    this.rippleInitListener = this.init.bind(
      this,
    ) as EventListenerOrEventListenerObject;
    document.addEventListener('DOMContentLoaded', this.rippleInitListener);
  }

  init(): void {
    this.rippleMouseDownListener = this.rippleMouseDown.bind(
      this,
    ) as EventListenerOrEventListenerObject;
    document.addEventListener('mousedown', this.rippleMouseDownListener, false);
  }

  rippleMouseDown(e: Element): void {
    for (
      let target: EventTarget = e as EventTarget; // target && target !== this;
      ;

    ) // target = e.parentNode as EventTarget;
    {
      if (!this.isVisible(target as HTMLElement)) {
        // continue;
      }

      if (this.selectorMatches(target as Element, '.ripplelink, .ui-button')) {
        const element = target;
        this.rippleEffect(element as HTMLElement, e);
        break;
      }
    }
  }

  selectorMatches(el: Element, selector: string): boolean {
    const p = Element.prototype;
    const f: (selectors: string) => boolean =
      // p.matches ||
      function f(s: string) {
        return (
          [].indexOf.call(document.querySelectorAll(s), this) !== -1 ||
          p.matches(s)
        );
      };
    return f.call(el, selector) as boolean;
  }

  isVisible(el: HTMLElement): boolean {
    return !!(el.offsetWidth || el.offsetHeight);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  rippleEffect(element: HTMLElement, e: any): void {
    if (element.querySelector('.ink') === null) {
      const inkEl = document.createElement('span');
      this.addClass(inkEl, 'ink');

      if (this.hasClass(element, 'ripplelink')) {
        element
          .querySelector('span')
          .insertAdjacentHTML('afterend', '<span class="ink"></span>');
      } else {
        element.appendChild(inkEl);
      }
    }

    const ink: HTMLElement = element.querySelector('.ink');
    this.removeClass(ink, 'ripple-animate');

    if (!ink.offsetHeight && !ink.offsetWidth) {
      const d = Math.max(element.offsetWidth, element.offsetHeight);
      ink.style.height = `${d}px`;
      ink.style.width = `${d}px`;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const x = e.pageX - this.getOffset(element).left - ink.offsetWidth / 2;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const y = e.pageY - this.getOffset(element).top - ink.offsetHeight / 2;

    ink.style.top = `${y}px`;
    ink.style.left = `${x}px`;
    ink.style.pointerEvents = 'none';
    this.addClass(ink, 'ripple-animate');
  }

  hasClass(element: Element, className: string): boolean {
    if (element.classList) {
      return element.classList.contains(className);
    }
    return new RegExp(`(^| )${className}( |$)`, 'gi').test(element.className);
  }

  addClass(element: Element, className: string): void {
    if (element.classList) {
      element.classList.add(className);
    } else {
      // eslint-disable-next-line no-param-reassign
      element.className += ` ${className}`;
    }
  }

  removeClass(element: Element, className: string): void {
    if (element.classList) {
      element.classList.remove(className);
    } else {
      // eslint-disable-next-line no-param-reassign
      element.className = element.className.replace(
        new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'),
        ' ',
      );
    }
  }

  getOffset(el: Element): { top: number; left: number } {
    const rect = el.getBoundingClientRect();

    return {
      top:
        rect.top +
        (window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0),
      left:
        rect.left +
        (window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0),
    };
  }

  unbindRipple(): void {
    if (this.rippleInitListener) {
      document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
    }
    if (this.rippleMouseDownListener) {
      document.removeEventListener('mousedown', this.rippleMouseDownListener);
    }
  }

  onLayoutClick(): void {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
  }

  onMenuButtonClick(event: Event): void {
    this.menuClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;

    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  onMenuClick(): void {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopbarMenuButtonClick(event: Event): void {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event: Event, item: boolean): void {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onRightPanelButtonClick(event: Event): void {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;
    event.preventDefault();
  }

  onRightPanelClick(): void {
    this.rightPanelClick = true;
  }

  hideOverlayMenu(): void {
    this.rotateMenuButton = false;
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isTablet(): boolean {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop(): boolean {
    return window.innerWidth > 1024;
  }

  isMobile(): boolean {
    return window.innerWidth <= 640;
  }

  isOverlay(): boolean {
    return this.layoutMode === MenuOrientation.OVERLAY;
  }

  isHorizontal(): boolean {
    return this.layoutMode === MenuOrientation.HORIZONTAL;
  }

  isSlim(): boolean {
    return this.layoutMode === MenuOrientation.SLIM;
  }

  changeToStaticMenu(): void {
    this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu(): void {
    this.layoutMode = MenuOrientation.OVERLAY;
  }

  changeToHorizontalMenu(): void {
    this.layoutMode = MenuOrientation.HORIZONTAL;
  }

  changeToSlimMenu(): void {
    this.layoutMode = MenuOrientation.SLIM;
  }

  ngOnDestroy(): void {
    this._isLive = false;
  }

  logout(): void {
    this.store.dispatch(new Logout(''));
  }

  toggleSwitchTenant(): void {
    this._switcherOkClicked = false;
    this._switcherButtonClicked = false;
    if (!this.showTenantSwitcher) {
      const queryParams: Params = {
        displayChangeCompany: this.showTenantSwitcher ? null : 'true',
      };

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }

    this.showTenantSwitcher = !this.showTenantSwitcher;
  }

  toggleSwitchTenantByButton($event: boolean): void {
    this._switcherOkClicked = false;
    this._switcherButtonClicked = true;
    if (!$event) {
      const queryParams: Params = {
        displayChangeCompany: this.showTenantSwitcher ? null : 'true',
      };

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
    if ($event) {
      this._switcherOkClicked = $event;
    }
    this.showTenantSwitcher = !this.showTenantSwitcher;
  }

  switcherDismissed(): void {
    if (!this._switcherButtonClicked) {
      const queryParams: Params = {
        displayChangeCompany: null,
      };
      this._switcherButtonClicked = false;
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }
  }

  gotoProfile(): void {
    this.store
      .pipe(
        select(AuthUserStore),
        filter((x) => !!x),
        take(1),
      )
      .subscribe(() => {
        this.router.navigate(['/profile']);
      });
  }

  addItem(items: MenuItem[], newItem: MenuItem): void {
    if (items.findIndex((x) => x.id === newItem.id) < 0) {
      return null;
    }
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  removeItem(items: MenuItem[], newItem: MenuItem): void {}

  // toggleTestTenant() {
  //   this.store
  //     .pipe(select(ActiveTenantStore), take(1))
  //     .subscribe(activeTenant => {
  //       if (!activeTenant.isTestTenant && activeTenant.testTenantId) {
  //         this.store.dispatch(
  //           new ActiveTenantChanging({
  //             tenantId: activeTenant.testTenantId,
  //             resetRoute: true
  //           })
  //         );
  //       } else {
  //         this.store.dispatch(
  //           new ActiveTenantChanging({
  //             tenantId: activeTenant.regularTenantId,
  //             resetRoute: true
  //           })
  //         );
  //       }
  //     });
  // }

  // swapTenantTestId(tenant) {
  //   tenant = { ...tenant };
  //   const testTenantId = tenant.testTenantId;
  //   tenant.testTenantId = tenant.tenantId;
  //   tenant.tenantId = testTenantId;
  //   return tenant;
  // }
  checkRouterEvent(routerEvent: AppRouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }
}
